import React, { Component } from "react";
import "./About.css";
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withFirebase } from 'react-redux-firebase'
import { Jumbotron } from 'react-bootstrap'

class About extends Component {

  render() {
    return (
      <div>
        <Jumbotron>
          <h1>Hi! This is Expo.</h1>
          <p>Expo is a low key way to share and to see things you might normally miss out on. Subscribe to a channel by texting <code className="code-light">subscribe channel-name</code> to <code className="code-light">+1-415-825-3976</code> and Expo will take care of the rest. It'll text you a new photo from that channel once per day.</p>

          <p>Yeah, that's really it. You can start your own channel if you want, but you don't have to. You can just subscribe to other channels and see what's happening once per day.</p>
        </Jumbotron>
        <Jumbotron>
          <h1>Once per day?</h1>
          <p>Yes. Let's keep it simple. Let's not get distracted or confused. Let's just take a look at something. Learn a little. Maybe just enjoy it. And then live your life. Nobody else is going to live it for you.</p>

          <p>I think of it as <code>slow social</code>.</p>
        </Jumbotron>
        <Jumbotron>
          <h1>Tell me about my data.</h1>
          <p>We respect your data. You should be aware that anyone can subscribe to your channel, but they can't reply or pester you. They can't even complain about your political views, at least not in a way that you can hear.</p>

          <p>This hack just started in early June 2019. The data may disappear if I screw something up. No promises, not yet.</p>
        </Jumbotron>
        <Jumbotron>
          <h1>Who is this?</h1>
          <p>I'm J.R. Head over to <a href="https://www.pike-house.com/">pike-house.com to find out</a> more about the degenerate clown who put this together.</p>

          <p>I'd be happy to hear from you: <code className="code-light">hello@pike-house.com</code>.</p>
        </Jumbotron>
      </div>
    );
  }
}

export default compose(
  withFirebase,
  connect(({ firebase: { auth } }) => ({ auth })),
)(About)
