import React from "react";
import "./Login.css";
import { Redirect } from 'react-router';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withFirebase, isLoaded, isEmpty } from 'react-redux-firebase'
import LoginComponent from '../components/LoginComponent'

function Login({firebase, auth}) {
  if (!isLoaded(auth)) {
    return null;
  }
  if (!isEmpty(auth)) {
    return <Redirect to="/" />;
  }
  return (
    <div className="Login">
      <div className="lander">
        <h1>Sign up or sign in</h1>
        <p>Sign in to create or manage an Expo channel. Expo needs your phone number to manage your channel.</p>
        <LoginComponent/>
      </div>
    </div>
  );
}

export default compose(
  withFirebase,
  connect(({ firebase: { auth } }) => ({ auth }))
)(Login)
