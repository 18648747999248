import React, { Component } from "react";
import PropTypes from "prop-types";
import "./CreatorLanding.css";
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withFirebase } from 'react-redux-firebase'
import ChannelCreateCard from '../components/ChannelCreateCard';
import ChannelDeleteCard from '../components/ChannelDeleteCard';
import ChannelUploadCard from '../components/ChannelUploadCard';
import ChannelItemsCard from '../components/ChannelItemsCard';
import { setCurrentCreator } from "../actions";

class CreatorLanding extends Component {

  componentDidMount() {
    const { creator, dispatch, auth } = this.props;
    if (!creator || creator === 'undefined') {
      console.log('setting creator id from auth');
      dispatch(setCurrentCreator(auth.uid));
    }
  }

  render() {
    console.dir(this.props);
    var { channels } = this.props;
    const userChannels = channels;

    let isFetching = !userChannels.fetchingInitiated || userChannels.isFetching;

    if (isFetching) {
      return this.renderFetching();
    }

    if (channels.error) {
      return this.renderError(channels.error);
    }

    let deleteCard, createCard, uploadCard, futureCard, pastCard;
    if (channels.items.length > 0) {
      deleteCard = (<ChannelDeleteCard />);
      createCard = null;
      uploadCard = (<ChannelUploadCard />);
      futureCard = (<ChannelItemsCard future={true}/>);
      pastCard = (<ChannelItemsCard future={false}/>);
    } else {
      uploadCard = null;
      deleteCard = null;
      futureCard = null;
      pastCard = null;
      createCard = (<ChannelCreateCard />);
    }

    return (
      <div className="CreatorLanding">
        <div className="lander">
          <div className="padded-row-big">
            {uploadCard}
          </div>
          <div className="padded-row-big">
            {futureCard}
          </div>
          <div className="padded-row-big">
            {pastCard}
          </div>
          <div className="padded-row-big">
            {deleteCard}
          </div>
          {createCard}
        </div>
      </div>
    );
  }

  renderFetching() {
    return (
      <p>Loading your Expo channel information...</p>
    );
  }

  renderError(error) {
    return (
      <div>
        <p>There was an error loading your Expo information: &nbsp;
        <code>{error}</code>.
        </p>
      </div>
    );
  }
}

CreatorLanding.propTypes = {
  firebase: PropTypes.object.isRequired,
  creator: PropTypes.string.isRequired,
  channels: PropTypes.object.isRequired,
};

const mapDispatchToProps = (_dispatch) => ({});

export default compose(
  withFirebase,
  connect(({ firebase: { auth } }) => ({ auth })),
  connect(({ creatorId, channels }) => ({ creator: creatorId, channels: channels })),
  connect(null, mapDispatchToProps)
)(CreatorLanding);
