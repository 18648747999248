import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withFirebase } from 'react-redux-firebase'
import { Card } from 'react-bootstrap';
import Gallery from 'react-photo-gallery';
import { fetchDiscoverChannels } from '../actions';
import { previewImageUrl } from '../imageUtils';

const RenderGalleryImage = ({ index, onClick, photo, margin, direction, top, left }) => {
  return (
    <div style={{ margin, height: photo.height, width: photo.width, backgroundImage: `url(${photo.src})`,   backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
 }}>
      <p className="text-over-image">{photo.channel}</p>
    </div >
  );
}

class ChannelsDiscoveryCard extends Component {

  componentDidMount() {
    const { dispatch, discoverChannels } = this.props;
    if (discoverChannels.uninitialized) {
      dispatch(fetchDiscoverChannels({}));
    }
  }

  render() {
    const { covers } = this.props;
    const photos = covers.map((i) => {
      let dim = i.cover.image.dimensions || { width: 256, height: 256 };
      return {
        src: previewImageUrl(i.cover.image),
        width: dim.width,
        height: dim.height,
        channel: `@${i.name}`
      };
    })
    return (
      <Card>
        <Card.Body>
          <Card.Title>Discover Channels</Card.Title>
          <Gallery photos={photos} renderImage={RenderGalleryImage} />
        </Card.Body>
      </Card>
    );
  }

}

ChannelsDiscoveryCard.propTypes = {
  firebase: PropTypes.object.isRequired,
  discoverChannels: PropTypes.object.isRequired,
  covers: PropTypes.array.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
});

export default compose(
  withFirebase,
  connect(({ firebase: { auth } }) => ({ auth })),
  connect(({ discoverChannels }) => ({ discoverChannels, covers: discoverChannels.coverImages })),
  connect(null, mapDispatchToProps)
)(ChannelsDiscoveryCard);
