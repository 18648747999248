import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withFirebase } from 'react-redux-firebase'
import { Button, Card } from 'react-bootstrap';
import { registerUpload } from "../actions";
import * as filestack from 'filestack-js';
import * as ImageUtils from '../imageUtils';


class ChannelUploadCard extends Component {
  constructor(props) {
    super(props)
    this.openUploadPicker = this.openUploadPicker.bind(this);
    this.onUploadFinished = this.props.onUploadFinished.bind(this);
  }

  componentDidMount() {
    this.uploadPicker = filestack.init(ImageUtils.FileStackId);
  }

  render() {
    const { channel } = this.props;
    return (
      <Card>
        <Card.Header>
          <code>@{channel.name}</code>
        </Card.Header>
        <Card.Body>
          <Card.Text>
            Your channel is named <code>{channel.name}</code>. It has _ subscribers. Your photos will be sent, in order, to your subscribers. One photo will be sent per day. Old school.

            Friends can follow your channel by texting <a href="sms:+1-415-825-3976"><code className="code-light">subscribe {channel.name}</code></a> to <a href="sms:+1-415-825-3976"><code className="code-light">+1-415-825-3976</code></a>.
            </Card.Text>
          <Button variant="primary" onClick={this.openUploadPicker}>
            Upload Photo
            </Button>
        </Card.Body>
      </Card>
    );
  }

  openUploadPicker() {
    const opt = {
      onUploadDone: (files) => this.fileUploaded(files),
      accept: ["image/*"],
      fromSources: ["local_file_system", "instagram", "url"],
      maxFiles: 1,
      disableTransformer: true
    }
    this.uploadPicker.picker(opt).open();
  }

  async fileUploaded(files) {
    let uploaded = files.filesUploaded;
    if (uploaded.length !== 1) {
      return;
    }

    var handle = uploaded[0].handle;
    let resized = await ImageUtils.resizeImage({ handle, fileSize: uploaded[0].size, url: uploaded[0].url });

    let image = uploaded[0];
    image.handle = resized.handle;
    image.url = resized.url;
    image.size = resized.fileSize;
    image.dimensions = resized.dim;

    const { creator, channel } = this.props;
    this.onUploadFinished(creator, channel.name, image, image.url);
  }
}

ChannelUploadCard.propTypes = {
  firebase: PropTypes.object.isRequired,
  creator: PropTypes.string.isRequired,
  channel: PropTypes.object.isRequired,
  onUploadFinished: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  onUploadFinished: (creatorId, channelName, image, url) => dispatch(registerUpload({ creatorId, channelName, image, url })),
});

export default compose(
  withFirebase,
  connect(({ firebase: { auth } }) => ({ auth })),
  connect(({ creatorId, channels }) => ({ creator: creatorId, channel: channels.items[0] })),
  connect(null, mapDispatchToProps)
)(ChannelUploadCard);
