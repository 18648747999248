import React from 'react';
import { Provider } from 'react-redux';
import './App.css';
import Routes from "./Routes";
import configureStore from './store';
import Amplify from 'aws-amplify';
import awsmobile from './aws-exports';

// Setup firebase & redux store

const initialState = window && window.__INITIAL_STATE__ // set initial state here
const store = configureStore(initialState);

// Setup AWS backend pointers

Amplify.configure(awsmobile);
Amplify.configure({
  API: {
    endpoints: [
      {
        name: 'expo-api-prod',
        endpoint: "https://prod.expo.api.pike-house.com"
      },
      {
        name: 'expo-api-test',
        endpoint: "https://test.expo.api.pike-house.com"
      },
      {
        name: 'expo-api-develop',
        endpoint: "https://develop.expo.api.pike-house.com"
      }
    ]
  }
});

export default () => (
  <Provider store={store}>
    <div className="App">
      <Routes />
    </div>
  </Provider>
);
