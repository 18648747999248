import React, { Component } from "react";
import PropTypes from "prop-types";
import "./SubscriptionsLanding.css";
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withFirebase } from 'react-redux-firebase'
import { Card, Accordion, Button, Row, Col, Container } from 'react-bootstrap';
import { fetchSubscriptions, unsubscribe } from "../api";
import appStage from '../app-stage';

class SubscriptionsCard extends Component {

  constructor(props) {
    super(props)
    this.state = {
      fetching: false,
      fetched: false,
      error: false,
      subs: null,
      unsubscribing: [],
    };
    this.onUnsubscribe = this.unsubscribeOne.bind(this);
  }

  componentDidMount() {
    this.fetchSubs();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.uid !== this.props.uid) {
      this.fetchSubs();
    }
  }

  async fetchSubs() {
    this.setState({ fetching: true });
    let answer = await fetchSubscriptions({ userId: this.props.uid });
    if (answer.error) {
      this.setState({ error: answer.error, fetching: false });
    }
    if (answer.subs) {
      this.setState({ subs: answer.subs, fetching: false, fetched: true });
    }
  }

  async unsubscribe({ channelName }) {
    var pending = (this.state.unsubscribing || []).concat([channelName]);
    this.setState({ unsubscribing: pending });
    let answer = await unsubscribe({ userId: this.props.uid, channelName });
    console.dir(answer);
    this.setState({ unsubscribing: this.state.unsubscribing.filter((c) => c !== channelName) });
    if (answer.error) {
      this.setState({ error: answer.error });
    }
    if (answer.result) {
      await this.fetchSubs();
    }
  }

  render() {
    if (this.state.error) {
      return (
        <Card>
          <Card.Body>
            <Card.Title>Error loading subscriptions</Card.Title>
            <Card.Text>
              There was an error.<br /> <code>{this.state.error}</code>
            </Card.Text>
          </Card.Body>
        </Card>
      );
    }
    if (this.state.fetching) {
      return this.renderFetching();
    }
    return (
      <>
        <Card>
          <Card.Body>
            <Card.Title>Subscriptions</Card.Title>
            <Card.Text>
            </Card.Text>
            <Container fluid={true}>
              {this.renderSubs()}
            </Container>
          </Card.Body>
        </Card>
        {this.renderDebug()}
      </>
    );
  }

  renderSubs() {
    if (!this.state.subs) {
      return null;
    }
    if (this.state.subs.length === 0) {
      return <Row><Col>You do not have any subscriptions.</Col></Row>;
    }
    return this.state.subs.map((sub) => {
      return (
        <Row key={sub.cid} className="subRow" noGutters={true}>
          <Col>
            <b>{sub.channelName}</b>
          </Col>
          <Col className="text-right" >
            {this.renderUnsubscribeButton({ sub })}
          </Col>
        </Row>
      );
    });
  }

  renderUnsubscribeButton({ sub }) {
    let pending = this.state.unsubscribing.find(c => sub.channelName === c);

    return (
      <Button
        variant="primary"
        onClick={pending ? null : () => { this.onUnsubscribe(sub); }}
        disabled={pending ? true : false}
        >
        {pending ? 'Unsubscribing...' : 'Unsubscribe'}
      </Button>
    );
  }

  renderFetching() {
    return (
      <Card>
        <Card.Body>
          <Card.Title>Subscriptions</Card.Title>
          <Card.Text>
            Loading subscriptions...
          </Card.Text>
        </Card.Body>
      </Card>
    );
  }

  renderDebug() {
    let debug;
    if (appStage !== 'prod') {
      debug = <div>
        For {appStage}, here's some debug state:
                <code>{JSON.stringify(this.state.subs, null, 2)}</code>
      </div>;
    } else {
      return null;
    }
    return (
      <Accordion>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0">
              Debug
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>{debug}</Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  }

  async unsubscribeOne(p) {
    this.unsubscribe({ channelName: p.channelName });
  }
}

SubscriptionsCard.propTypes = {
  uid: PropTypes.string.isRequired
};


class SubscriptionsLanding extends Component {

  constructor(props) {
    super(props)
  }

  render() {
    let { auth } = this.props;
    if (!auth) { return null; }
    let { uid } = auth;
    if (!uid) { return null; }
    return (
      <div className="SubscriptionsLanding">
        <div className="lander">
          <div className="padded-row-big">
            <SubscriptionsCard uid={uid} />
          </div>
          <div className="padded-row-big">
          </div>
        </div>
      </div>
    );
  }
}

SubscriptionsLanding.propTypes = {
  firebase: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapDispatchToProps = (_) => ({
});

export default compose(
  withFirebase,
  connect(({ firebase: { auth } }) => ({ auth })),
  connect(null, mapDispatchToProps)
)(SubscriptionsLanding);
