import React from 'react'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withFirebase } from 'react-redux-firebase'

function Logout({ firebase, _auth }) {
  function logout() {
    console.log('logging out');
    firebase.logout();
  }
  return (
    <Link to="/" onClick={logout}>Sign out</Link>
  );
}

export default compose(
  withFirebase,
  connect(({ firebase: { auth } }) => ({ auth }))
)(Logout)
