import { combineReducers } from 'redux'
import { reducer as firebase } from 'react-redux-firebase'
import { decorateChannelItemsWithPublishDetails } from './channel-item-state';

import {
  SELECT_CREATOR,
  REQUEST_CREATOR_CHANNELS,
  RECEIVE_CREATOR_CHANNELS,
  REQUEST_DELETE_CHANNEL,
  RECEIVE_DELETED_CHANNEL,
  REQUEST_CREATE_CHANNEL,
  RECEIVE_CREATED_CHANNEL,
  REQUEST_UPLOAD_IMAGE,
  RECEIVED_UPLOADED_IMAGE,
  REQUEST_CHANNEL_ITEMS,
  RECEIVED_CHANNEL_ITEMS,
  REQUEST_DISCOVER_CHANNELS,
  RECEIVE_DISCOVER_CHANNELS,
  IMAGE_DELETED,
} from './actions'

function creatorId(state = 'undefined', action) {
  switch (action.type) {
    case SELECT_CREATOR:
      return action.creator
    default:
      return state
  }
}

function channelsFromAction(
  state = {
    isFetching: false,
    didInvalidate: false,
    items: []
  },
  action
) {
  switch (action.type) {
    case REQUEST_CREATOR_CHANNELS:
    case REQUEST_DELETE_CHANNEL:
    case REQUEST_CREATE_CHANNEL:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
        fetchingInitiated: true,
      });
    case RECEIVE_CREATOR_CHANNELS:
    case RECEIVE_CREATED_CHANNEL:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        fetchingInitiated: true,
        items: action.channels,
        lastUpdated: action.receivedAt,
        error: action.error,
      });
    case RECEIVE_DELETED_CHANNEL:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        items: [],
        error: action.error,
        lastUpdated: action.receivedAt
      });
    default:
      return state
  }
}

function channels(state = { fetchInitiated: false }, action) {
  switch (action.type) {
    case REQUEST_CREATOR_CHANNELS:
    case RECEIVE_CREATOR_CHANNELS:
    case RECEIVE_CREATED_CHANNEL:
    case RECEIVE_DELETED_CHANNEL:
      return Object.assign({}, state,
        channelsFromAction(state[action.creator], action));
    default:
      return state
  }
}


function uploadImageFromAction(
  state = {
    isFetching: false,
    didInvalidate: false,
    items: []
  },
  action
) {
  switch (action.type) {
    case REQUEST_UPLOAD_IMAGE:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case RECEIVED_UPLOADED_IMAGE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        uploadImage: action.uploadImage,
        error: action.error,
      });
    default:
      return state
  }
}

function uploadImage(state = {}, action) {
  switch (action.type) {
    case REQUEST_UPLOAD_IMAGE:
    case RECEIVED_UPLOADED_IMAGE:
      return Object.assign({}, state, uploadImageFromAction(state[action.creator], action)
      );
    default:
      return state
  }
}

function imagesFromAction(
  state = {
    isFetching: false,
    didInvalidate: false,
    items: []
  },
  action
) {
  const makeItems = (i, c) => {
    return decorateChannelItemsWithPublishDetails(i, c);
  }
  switch (action.type) {
    case REQUEST_CHANNEL_ITEMS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
        items: []
      });
    case RECEIVED_CHANNEL_ITEMS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: makeItems(action.items, action.channel),
        channel: action.channel,
        error: action.error,
      });
    case RECEIVED_UPLOADED_IMAGE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: makeItems(state.items.concat(action.item), state.channel),
        channel: state.channel,
        error: action.error,
      });
    case RECEIVE_DELETED_CHANNEL:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        items: [],
        channel: action.deletedChannel.channel,
        error: action.error,
      });
    case IMAGE_DELETED:
      const filterDeletedImage = (items, iid) => {
        return items.filter((i) => {
          return i.iid !== iid;
        });
      }
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: makeItems(filterDeletedImage(state.items, action.imageId), state.channel),
        channel: state.channel,
        error: action.error,
      });
    default:
      return state
  }
}

function images(state = { isFetching: false, items: [] }, action) {
  switch (action.type) {
    case REQUEST_CHANNEL_ITEMS:
    case RECEIVED_CHANNEL_ITEMS:
    case RECEIVED_UPLOADED_IMAGE:
    case RECEIVE_DELETED_CHANNEL:
    case IMAGE_DELETED:
      return Object.assign({}, state, imagesFromAction(state, action));
    default:
      return state
  }
}

function createError(state = { message: null }, action) {
  switch (action.type) {
    case RECEIVE_CREATED_CHANNEL:
      return Object.assign({}, state, { message: action.error });
    default:
      return state
  }
}

const filterCovers = (channels) => {
  if (!channels) {
    return [];
  }

  return channels.filter((c) => c.cover).sort((a, b) => b.lastPublishedSeq.localeCompare(a.lastPublishedSeq));
}

function discoverChannels(state = { isFetching: false, items: [], uninitialized: true, coverImages: [] }, action) {
  switch (action.type) {
    case REQUEST_DISCOVER_CHANNELS:
    case RECEIVE_DISCOVER_CHANNELS:
      return Object.assign({}, state, {
        channels: action.channels,
        coverImages: filterCovers(action.channels),
        error: action.error,
      });
    default:
      return state
  }
}

const rootReducer = combineReducers({
  firebase,
  // firestore // add this for firestore
  channels,
  creatorId,
  uploadImage,
  images,
  createError,
  discoverChannels,
})

export default rootReducer
