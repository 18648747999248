import { applyMiddleware, createStore, compose } from 'redux'
import rootReducer from './reducer'
import { FirebaseConfig as fbConfig } from './firebase-config'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore' // make sure you add this for firestore
import { reactReduxFirebase } from 'react-redux-firebase'
import { reduxFirestore } from 'redux-firestore'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'

const loggerMiddleware = createLogger()

export default function configureStore (initialState, history) {
  // Initialize Firebase instance
  firebase.initializeApp(fbConfig)

  const reduxFirebaseConfig = {
    userProfile: 'users',
    useFirestoreForProfile: false,
    enableLogging: false
  }

  const middleware = [
    thunkMiddleware.withExtraArgument(firebase),
    loggerMiddleware,
  ];

  const createStoreWithMiddleware = compose(
    // enhance store with store.firebase
    reactReduxFirebase(firebase, reduxFirebaseConfig),
    // enhance store with store.firestore
    reduxFirestore(firebase),
    applyMiddleware(...middleware),
    // support redux devtools
    typeof window === 'object' && typeof window.__REDUX_DEVTOOLS_EXTENSION__ === 'function' ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
  )(createStore)
  const store = createStoreWithMiddleware(rootReducer)

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducer', () => {
      const nextRootReducer = require('./reducer')
      store.replaceReducer(nextRootReducer)
    })
  }

  return store
}
