import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Link } from 'react-router-dom'
import Login from "./containers/Login";
import Home from "./containers/Home";
import About from "./containers/About";
import CreatorLanding from "./containers/CreatorLanding";
import PhotoLanding from "./containers/PhotoLanding";
import SubscriptionsLanding from "./containers/SubscriptionsLanding";
import LoggedIn from "./containers/CreatorLanding";
import { UserIsAuthenticated, UserIsNotAuthenticated } from "./components/UserAuthenticated";
import { Container, Row, Col } from 'react-bootstrap';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withFirebase } from 'react-redux-firebase'
import LogoutComponent from './components/LogoutComponent';
import ScrollToTop from './components/ScrollToTop';
import createHistory from 'history/createBrowserHistory'
import ReactGA from 'react-ga'
import NavBar from './components/NavBar';

const history = createHistory();
history.listen(location => {
  ReactGA.set({ page: location.pathname })
  ReactGA.pageview(location.pathname)
});

class AppRouter extends Component {

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
  }

  render() {
    return (
      <BrowserRouter history={history}>
        <ScrollToTop>
          <Container>
            <NavBar />
            <Row className="justify-content-md-center padded-row-big">
              <Col lg="7">
                <Switch>
                  <Route path="/" exact component={Home} />
                  <Route path="/login" exact component={UserIsNotAuthenticated(Login)} />
                  <Route path="/creator" exact component={UserIsAuthenticated(CreatorLanding)} />
                  <Route path="/photo/:channel/:iid" exact component={UserIsAuthenticated(PhotoLanding)} />
                  <Route path="/subscriptions" exact component={UserIsAuthenticated(SubscriptionsLanding)} />
                  <Route path="/loggedin" exact component={LoggedIn} />
                  <Route path="/about" exact component={About} />
                </Switch>
              </Col>
            </Row>
            <Row className="justify-content-md-center padded-row-big">
              <Col xs lg="3">
              </Col>
              <Col lg="1"></Col>
              <Col xs lg="3" className="just-right">
                <Link to="/about">About</Link> | <LogoutComponent />
              </Col>
            </Row>
          </Container>
        </ScrollToTop>
      </BrowserRouter>
    )
  }
}


export default compose(
  withFirebase,
  connect(({ firebase: { auth } }) => ({ auth }))
)(AppRouter)
