import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withFirebase } from 'react-redux-firebase'
import { Card, Pagination } from 'react-bootstrap';
import { fetchChannelItems } from '../actions';
import Gallery from 'react-photo-gallery';
import { previewImageUrl, RenderGalleryImage } from '../imageUtils';

const GalleryPageSize = 12;

class ChannelItemsCard extends Component {
  constructor(props) {
    super(props)
    this.state = this.genGalleryState(props);
    this.fetched = false;
    this.firstPage = this.firstPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this.lastPage = this.lastPage.bind(this);
  }

  componentDidMount() {
    const { items, dispatch, channel } = this.props;
    if (!this.fetched && items.items.length === 0) {
      this.fetched = true;
      dispatch(fetchChannelItems(channel.name));
    }
  }

  componentDidUpdate(prevProps) {
    if (!(prevProps.items.items.length === this.props.items.items.length)) {
      this.setState(this.genGalleryState(this.props));
    }
  }

  genGalleryState(props) {
    let paging = {
      page: 0,
      pageCount: Math.ceil(props.items.items.length / GalleryPageSize),
    };
    let images = {
      images: props.items.items.filter((i) => props.future ? !i.published : i.published),
    };
    return { ...images, ...paging };
  }

  render() {
    const { channel, items } = this.props;
    if (items.error) {
      return (
        <Card>
          <Card.Body>
            <Card.Title>Photos</Card.Title>
            <Card.Text>
              There was an error loading the photos.
            </Card.Text>
          </Card.Body>
        </Card>
      );
    }
    const first = this.state.page * GalleryPageSize;
    const page = this.state.images.slice(first, first + GalleryPageSize);
    const photos = page.map((i) => {
      let dim = i.image.dimensions || { width: 256, height: 256 };
      return {
        src: previewImageUrl(i.image),
        width: dim.width,
        height: dim.height,
        item: i,
        published: i.publish,
        detailsUrl: `/photo/${this.props.channel.name}/${i.iid}`,
      }
    });
    const backPaging = (this.state.page === 0) ? null : (
      <>
        <Pagination.First onClick={this.firstPage} />
        <Pagination.Prev onClick={this.prevPage} />
      </>
    );
    const nextPaging = (this.state.page === this.state.pageCount - 1) ? null : (
      <>
        <Pagination.Next onClick={this.nextPage} />
        <Pagination.Last onClick={this.lastPage} />
      </>
    );
    let description;
    if (this.props.future) {
      description = (
        <>
          These are the photos that will be sent to <code>@{channel.name}</code> susbscribers in the future.
        </>
      );
    } else {
      description = (
        <>
          These are the photos that have already been published to <code>@{channel.name}</code> thus far.
        </>
      );
    }
    return (
      <Card>
        <Card.Body>
          <Card.Title>{this.props.future ? 'Queued' : 'Published'} Photos</Card.Title>
          <Card.Text>
            {description}
          </Card.Text>
          <Gallery photos={photos} renderImage={RenderGalleryImage} />
          <Pagination>
            {backPaging}
            {nextPaging}
          </Pagination>
        </Card.Body>
      </Card>
    );
  }

  firstPage() {
    this.pageChanged(0);
  }

  lastPage() {
    this.pageChanged(this.state.pageCount - 1);
  }

  prevPage() {
    if (this.state.page === 0) { return; }
    this.pageChanged(this.state.page - 1);
  }

  nextPage() {
    if (this.state.page === this.state.pageCount - 1) { return; }
    this.pageChanged(this.state.page + 1);
  }

  pageChanged(newPage) {
    this.setState({ page: newPage });
  }
}

ChannelItemsCard.propTypes = {
  firebase: PropTypes.object.isRequired,
  items: PropTypes.object.isRequired,
  channel: PropTypes.object.isRequired,
  future: PropTypes.bool.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
});

export default compose(
  withFirebase,
  connect(({ firebase: { auth } }) => ({ auth })),
  connect(({ creatorId, channels, images }) => ({
    creator: creatorId,
    channel: channels.items[0],
    items: images
  })),
  connect(null, mapDispatchToProps)
)(ChannelItemsCard);
