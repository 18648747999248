import React, { Component } from "react";
import PropTypes from "prop-types";
import "./PhotoLanding.css";
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withFirebase } from 'react-redux-firebase'
import { Card, Accordion, Button } from 'react-bootstrap';
import { fetchOneItem, deleteOneItem } from "../api";
import { Redirect } from 'react-router';
import appStage from '../app-stage';
import Gallery from 'react-photo-gallery';
import { previewImageUrl, RenderGalleryImage } from '../imageUtils';
import { humanPublishString } from '../channel-item-state';
import DeleteModal from '../components/DeleteModal';
import { imageDequeued } from '../actions';

class ItemCard extends Component {

  constructor(props) {
    super(props)
    this.state = {
      fetching: false,
      fetched: false,
      error: false,
      item: null,
      confirmingDequeue: false,
      deleted: false,
    };
    this.onDequeue = this.onDequeue.bind(this);
    this.onDequeueConfirm = this.onDequeueConfirm.bind(this);
    this.onDequeueCancel = this.onDequeueCancel.bind(this);
  }

  componentDidMount() {
    this.fetchItem();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.itemId !== this.props.itemId) {
      this.fetchItem();
    }
  }

  async fetchItem() {
    this.setState({ fetching: true });
    let { itemId, channelName } = this.props;
    let answer = await fetchOneItem({ itemId, channelName });
    if (answer.error) {
      this.setState({ error: answer.error, fetching: false });
    }
    if (answer.item) {
      this.setState({ item: answer.item, channel: answer.channel, fetching: false, fetched: true });
    }
  }

  render() {
    const { itemId, channelName } = this.props;
    if (this.state.error) {
      return (
        <Card>
          <Card.Body>
            <Card.Title>Error loading photo</Card.Title>
            <Card.Text>
              There was an error.<br /> <code>{this.state.error}</code>
            </Card.Text>
          </Card.Body>
        </Card>
      );
    }
    if (this.state.fetching || !this.state.item) {
      return this.renderFetching();
    }
    if (this.state.deleted) {
      return <Redirect to="/creator" />;
    }
    let i = this.state.item;
    let dim = i.image.dimensions || { width: 256, height: 256 };
    let photo = {
      src: previewImageUrl(i.image),
      width: dim.width,
      height: dim.height,
      item: i
    };

    return (
      <>
        <Card>
          <Card.Body>
            <Card.Title>{channelName} / {itemId}</Card.Title>
            <Gallery photos={[photo]} renderImage={RenderGalleryImage} />
            <Card.Text>
              {this.renderImageText()}
            </Card.Text>
            {this.renderDequeue()}
          </Card.Body>
        </Card>
        {this.renderDebug()}
      </>
    );
  }

  renderFetching() {
    return (
      <Card>
        <Card.Body>
          <Card.Title>Photo</Card.Title>
          <Card.Text>
            Loading photo...
            </Card.Text>
        </Card.Body>
      </Card>
    );
  }

  renderImageText() {
    let i = this.state.item;
    if (!i) { return null; }
    if (i.hasPublished) {
      let str = humanPublishString(i, this.state.channel);
      if (str) {
        return (
          <>
            This photo was published to subscribers of <code>@{this.state.channel.name}</code> {str}.
          </>
        );
      } else {
        return (
          <>
            This photo was published to subscribers of <code>@{this.state.channel.name}</code>.
          </>
        );
      }
    } else {
      return (
        <>
          This photo is queued for publish. Everyone subscribed to <code>@{this.state.channel.name}</code> will receive an SMS message with this image.
        </>
      );
    }
  }

  renderDequeue() {
    let i = this.state.item;
    if (!i) { return null; }
    if (i.published) {
      return null;
    }
    return (
      <>
        <Button variant="primary" onClick={this.onDequeue}>
          Delete
        </Button>
        <DeleteModal title="Delete Photo" body="Are you sure you want to remove this photo from the publishing queue? You won't be able to retrieve it again." onDelete={this.onDequeueConfirm} onCancel={this.onDequeueCancel} show={this.state.confirmingDequeue} />
      </>
    );
  }

  renderDebug() {
    let debug;
    if (appStage !== 'prod') {
      debug = <div>
        For {appStage}, here's some debug state:
                <code>{JSON.stringify(this.state.item, null, 2)}</code>
      </div>;
    } else {
      return null;
    }
    return (
      <Accordion>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0">
              Debug
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>{debug}</Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  }

  onDequeue() {
    this.setState({ confirmingDequeue: true });
  }

  onDequeueCancel() {
    this.setState({ confirmingDequeue: false });
  }

  async onDequeueConfirm() {
    let { itemId, channelName } = this.props;
    let result = await deleteOneItem({ itemId, channelName });
    if (result.result === 'deleted') {
      this.props.onDequeued(itemId, channelName);
      this.setState({ deleted: true });
    } else {
      this.setState({ error: result.error || `Error deleting image` });
    }
  }
}

ItemCard.propTypes = {
  onDequeued: PropTypes.func.isRequired
};


class PhotoLanding extends Component {

  constructor(props) {
    super(props)
    this.onDequeued = this.props.onDequeued.bind(this);
  }

  render() {
    let { channel, iid } = this.props.match.params;
    if (!channel || !iid) {
      return <Redirect to="/" />;
    }
    return (
      <div className="PhotoLanding">
        <div className="lander">
          <div className="padded-row-big">
            <ItemCard itemId={iid} channelName={channel} onDequeued={this.onDequeued}/>
          </div>
          <div className="padded-row-big">
          </div>
        </div>
      </div>
    );
  }
}

PhotoLanding.propTypes = {
  onDequeued: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  onDequeued: (imageId) => dispatch(imageDequeued({ imageId })),
});

export default compose(
  withFirebase,
  connect(null, mapDispatchToProps)
)(PhotoLanding);
