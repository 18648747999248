import { API } from 'aws-amplify';
import firebase from 'firebase/app'
import 'firebase/auth'
import appStage from './app-stage';

export const SELECT_CREATOR = 'SELECT_CREATOR'
export function selectCreator(creator) {
  return {
    type: SELECT_CREATOR,
    creator
  }
}

export const REQUEST_CREATOR_CHANNELS = 'REQUEST_CREATOR_CHANNELS'
function requestCreatorChannels(creator) {
  return {
    type: REQUEST_CREATOR_CHANNELS,
    creator
  }
}

export const RECEIVE_CREATOR_CHANNELS = 'RECEIVE_CREATOR_CHANNELS'
function receiveCreatorChannels(creator, networkResponse) {
  return {
    type: RECEIVE_CREATOR_CHANNELS,
    creator,
    channels: networkResponse.channels,
    error: errMsgResponseOrNull(networkResponse),
    receivedAt: Date.now()
  }
}

export const REQUEST_CHANNEL_ITEMS = 'REQUEST_CHANNEL_ITEMS'
function requestChannelItems(channelName) {
  return {
    type: REQUEST_CHANNEL_ITEMS,
    channelName: channelName,
  }
}

export const RECEIVED_CHANNEL_ITEMS = 'RECEIVED_CHANNEL_ITEMS'
function receiveChannelItems(networkResponse) {
  return {
    type: RECEIVED_CHANNEL_ITEMS,
    items: networkResponse.items,
    channel: networkResponse.channel,
    error: errMsgResponseOrNull(networkResponse),
    receivedAt: Date.now()
  }
}


export const REQUEST_CREATE_CHANNEL = 'REQUEST_CREATE_CHANNEL'
function requestCreateChannel(creatorId, channelName) {
  return {
    type: REQUEST_CREATE_CHANNEL,
    create: { creatorId, channelName }
  }
}

export const RECEIVE_CREATED_CHANNEL = 'RECEIVE_CREATED_CHANNEL'
function receiveCreatedChannel(creator, networkResponse) {
  return {
    type: RECEIVE_CREATED_CHANNEL,
    creator,
    channels: networkResponse.channels,
    error: errMsgResponseOrNull(networkResponse),
    receivedAt: Date.now()
  }
}

export const REQUEST_DELETE_CHANNEL = 'REQUEST_DELETE_CHANNEL'
function requestDeleteChannel(creatorId, channelName) {
  return {
    type: REQUEST_DELETE_CHANNEL,
    deleteChannel: { creatorId, channelName }
  }
}

export const RECEIVE_DELETED_CHANNEL = 'RECEIVE_DELETED_CHANNEL'
function receiveDeletedChannel(creator, channel) {
  return {
    type: RECEIVE_DELETED_CHANNEL,
    creator,
    channels: [],
    deletedChannel: { creator, channel },
    receivedAt: Date.now()
  }
}

export const REQUEST_UPLOAD_IMAGE = 'REQUEST_UPLOAD_IMAGE'
function requestUploadImage(creator, channel, image, url) {
  return {
    type: REQUEST_UPLOAD_IMAGE,
    creator,
    uploadImage: { image, channel, url }
  }
}


export const RECEIVED_UPLOADED_IMAGE = 'RECEIVED_UPLOADED_IMAGE'
function receiveUploadImage(creator, networkResponse) {
  return {
    type: RECEIVED_UPLOADED_IMAGE,
    creator,
    uploadImage: {
      image: networkResponse.result.image,
      url: networkResponse.result.url,
      channel: networkResponse.result.channel,
    },
    item: networkResponse.result.item,
    error: errMsgResponseOrNull(networkResponse)
  };
}

export const REQUEST_DISCOVER_CHANNELS = 'REQUEST_DISCOVER_CHANNELS'
function requestDiscoverChannels(continuation) {
  return {
    type: REQUEST_DISCOVER_CHANNELS,
    continuation: continuation,
  }
}

export const RECEIVE_DISCOVER_CHANNELS = 'RECEIVE_DISCOVER_CHANNELS'
function receiveDiscoverChannels(continuation, networkResponse) {
  return {
    type: RECEIVE_DISCOVER_CHANNELS,
    continuation,
    channels: networkResponse.items,
    error: errMsgResponseOrNull(networkResponse),
    receivedAt: Date.now()
  }
}

export const IMAGE_DELETED = 'IMAGE_DELETED'
function imageDeleted(imageId) {
  return {
    type: IMAGE_DELETED,
    imageId
  };
}


export function setCurrentCreator(creatorId) {
  return async function(dispatch) {
    dispatch(selectCreator(creatorId));
    dispatch(fetchCreatorChannels(creatorId));
  }
}

export function fetchCreatorChannels(creator) {
  return async function(dispatch) {
    dispatch(requestCreatorChannels(creator));
    let result = await backendFetchCreatorChannels(creator);
    dispatch(receiveCreatorChannels(creator, result));
  }
}

export function fetchDiscoverChannels(continuation) {
  return async function(dispatch) {
    dispatch(requestDiscoverChannels(continuation));
    let result = await backendFetchDiscoverChannels(continuation);
    dispatch(receiveDiscoverChannels(continuation, result));
  }
}


export function fetchChannelItems(channelName) {
  return async function(dispatch) {
    dispatch(requestChannelItems(channelName));
    let result = await backendFetchChannelItems(channelName);
    dispatch(receiveChannelItems(result));
  }
}

export function createChannel({ creatorId, channelName }) {
  return async function(dispatch) {
    dispatch(requestCreateChannel(creatorId));
    let result = await backendCreateChannel(creatorId, channelName);
    dispatch(receiveCreatedChannel(creatorId, result));
  }
}

export function deleteChannel({ creatorId, channelName }) {
  return async function(dispatch) {
    dispatch(requestDeleteChannel(creatorId));
    let result = await backendDeleteChannel(creatorId, channelName);
    dispatch(receiveDeletedChannel(creatorId, result));
  }
}

export function registerUpload({ creatorId, channelName, image, url }) {
  return async function(dispatch) {
    dispatch(requestUploadImage(creatorId, channelName, image, url));
    let result = await backendRegisterUpload(creatorId, channelName, image, url);
    dispatch(receiveUploadImage(creatorId, result));
  }
}

export function imageDequeued({imageId}) {
  return async function(dispatch) {
    dispatch(imageDeleted(imageId));
  }
}

async function headersWithAuth(headers = {}) {
  if (!firebase.auth().currentUser) {
    return Object.assign({}, headers, {});
  }
  let firebaseJWT = await firebase.auth().currentUser.getIdToken();
  return Object.assign({}, headers, {
    'X-Expo-Authorization': firebaseJWT
  });
}

async function backendFetchCreatorChannels(uid) {
  let stage = appStage;
  let apiName = `expo-api-${stage}`;
  let path = `/channels/creator/${uid}`;
  try {
    let ini = { headers: await headersWithAuth() };
    let result = await API.get(apiName, path, ini);
    if (result.error) {
      return {error: result.error};
    }
    return { channels: result.channels.filter((c) => c) };
  } catch (error) {
    console.dir({ error });
    return { error };
  }
}

async function backendFetchDiscoverChannels(continuation) {
  let stage = appStage;
  let apiName = `expo-api-${stage}`;
  let path = `/channels/list`;
  let queryStringParameters = {covers: true};
  if (continuation) {
    queryStringParameters.continuation = continuation;
  }
  let ini = { queryStringParameters }; // no auth needed
  try {
    let result = await API.get(apiName, path, ini);
    return result;
  } catch (error) {
    console.dir({ error });
    return { error };
  }
}

async function backendFetchChannelItems(channelName) {
  let stage = appStage;
  let apiName = `expo-api-${stage}`;
  let path = `/channels/images/${channelName}`;
  let ini = {headers: await headersWithAuth()};
  try {
    let result = await API.get(apiName, path, ini);
    return {
      items: result.result.items,
      channel: result.result.channel
    };
  } catch (error) {
    console.dir({ error });
    return { error };
  }
}

async function backendCreateChannel(creatorId, channelName) {
  let stage = appStage;
  let apiName = `expo-api-${stage}`;
  let path = `/channels/create/${creatorId}/${channelName}`;
  let ini = {headers: await headersWithAuth()};
  try {
    let result = await API.post(apiName, path, ini);
    if (result.result.error) {
      return { error: result.result.error, channels: [] };
    }
    let rr = { channels: [result.result.channel] };
    return rr;
  } catch (error) {
    console.dir(error);
    return { error };
  }
}

async function backendDeleteChannel(creatorId, channelName) {
  let stage = appStage;
  let apiName = `expo-api-${stage}`;
  let path = `/channels/delete/${creatorId}/${channelName}`;
  let ini = {headers: await headersWithAuth()};
  try {
    let result = await API.post(apiName, path, ini);
    return result.channels;
  } catch (error) {
    console.dir(error);
  }
}

async function backendRegisterUpload(creatorId, channelName, image, url) {
  let stage = appStage;
  let apiName = `expo-api-${stage}`;
  let path = `/channels/images/${channelName}`;
  let body = {
    image, url, creatorId
  };
  let ini = {
    body,
    headers: await headersWithAuth()
  };
  try {
    let result = await API.post(apiName, path, ini);
    return result;
  } catch (error) {
    return { error };
  }
}


function errMsgResponseOrNull(networkResponse) {
  if (!networkResponse || !networkResponse.error) {
    return null;
  }
  let message = networkResponse.error.message;
  let noMessage = networkResponse.error || 'Internal error';
  return message || noMessage;
}
