import React from "react";
import { Link } from 'react-router-dom'

export const FileStackId = 'ADCdkhBkR8iOMTo5xwdfAz';
export const LargestSupportedImageSize = 1024 * 1024 * 5;

export const makeImageTaskUrl = ({ handle, task }) => {
  return `https://cdn.filestackcontent.com/${FileStackId}/${task}/${handle}`;
}

export const makeImgixViewUrl = ({ handle, task }) => {
  return `https://expo-app.imgix.net/${handle}?${task}`;
}


export const getProperlyOrientedImage = async ({ handle }) => {
  let url = makeImageTaskUrl({ handle, task: `rotate=deg:exif/store` });
  var response = await fetch(url);
  return await response.json();
}

export const getImageDimensions = async ({ handle }) => {
  var url = makeImageTaskUrl({ handle, task: 'imagesize' });
  var response = await fetch(url);
  return await response.json();
}

export const resizeImage = async ({ handle, fileSize, url }) => {
  var fixed = await getProperlyOrientedImage({ handle });
  handle = fixed.handle;
  url = fixed.url;
  var dim = await getImageDimensions({ handle });
  if (fileSize < LargestSupportedImageSize) {
    return { handle: handle, dim, fileSize, url };
  }
  let ratio = (LargestSupportedImageSize * 0.9) / fileSize;
  let newDim = { height: Math.trunc(dim.height * ratio), width: Math.trunc(dim.width * ratio) };
  let resizeUrl = makeImageTaskUrl({ handle, task: `resize=height:${newDim.height}/store` });
  let response = await fetch(resizeUrl);
  let resizedImage = await response.json();
  dim = await getImageDimensions({ handle: resizedImage.handle });
  return { handle: resizedImage.handle, dim, fileSize: resizedImage.size, url: resizedImage.url };
}

const isFileStackImage = (image) => {
  return image.url.includes(`filestack`) === true;
}

export function previewImageUrl(image) {
  if (image.urls && image.urls.length >= 2) {
    return image.urls[1];
  }
  let handle = image.handle;
  if (isFileStackImage(image)) {
    let previewSizeTask = `resize=width:600`;
    return makeImageTaskUrl({ handle, task: previewSizeTask });
  } else {
    return makeImgixViewUrl({ handle, task: `w=600` });
  }
}

export const RenderGalleryImage = ({ /* index, onClick, */ photo, margin /* direction, top, left */ }) => {
  let overlay;
  if (photo.published) {
    overlay = <p className="text-over-image">{photo.published}</p>;
  } else {
    overlay = null;
  }
  let img =
    <div style={{
      margin, height: photo.height, width: photo.width, backgroundImage: `url(${photo.src})`, backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    }}>
      {overlay}
    </div>;
  if (photo.detailsUrl) {
    return <Link to={photo.detailsUrl}>{img}</Link>;
  }
  return img;
}
