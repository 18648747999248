import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withFirebase } from 'react-redux-firebase'
import { Form, Button, Card } from 'react-bootstrap';
import { createChannel } from "../actions";

const makeValidChannelName = (n) => {
  return n.replace(/\s/g, "").replace(/@/g, "").toLowerCase();
}

class ChannelCreateCard extends Component {
  constructor(props) {
    super(props)
    this.onCreateChannel = this.props.onCreateChannel.bind(this);
    this.onChannelInputChange = this.onChannelInputChange.bind(this);
  }

  componentDidMount() {
  }

  onChannelInputChange(event) {
    event.target.value = makeValidChannelName(event.target.value);
  }

  render() {
    console.dir(this.props);
    var { creator, onCreateChannel } = this.props;
    let channelInput;
    return (
      <Card style={{ width: '18rem' }}>
        <Card.Body>
          <Card.Title>Channel</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            Create your own Expo channel
          </Card.Subtitle>
          <Card.Text>
            You do not have a channel. You can create a channel for others to subscribe to. Every creator can have one channel.
          </Card.Text>
          <Card.Body>
            <Form>
              <Form.Group controlId="formChannelName">
                <Form.Control type="text" placeholder="Name your new channel" ref={node => (channelInput = node)} onChange={this.onChannelInputChange} />
                <Form.Text className="text-muted">
                  All lowercase. No spaces.
                </Form.Text>
                {this.renderError()}
              </Form.Group>
            </Form>
          </Card.Body>
          <Button variant="primary" onClick={() => onCreateChannel(creator, channelInput.value)}>
            Create Channel
          </Button>
        </Card.Body>
      </Card>
    );
  }

  renderError() {
    if (!this.props.createError.message) {
      return null;
    }
    return (
      <Form.Text>
        <b>{this.props.createError.message}</b>
      </Form.Text>
    )
  }
}

ChannelCreateCard.propTypes = {
  onCreateChannel: PropTypes.func.isRequired,
  firebase: PropTypes.object.isRequired,
  creator: PropTypes.string.isRequired,
  auth: PropTypes.object.isRequired,
  createError: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => (
  {
    onCreateChannel: (creatorId, channelName) => dispatch(createChannel({ creatorId, channelName })),
  });

export default compose(
  withFirebase,
  connect(({ firebase: { auth } }) => ({ auth })),
  connect(({ creatorId }) => ({ creator: creatorId })),
  connect(({ createError }) => ({ createError })),
  connect(null, mapDispatchToProps)
)(ChannelCreateCard);
