import React, { Component } from "react";
import "./Home.css";
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withFirebase } from 'react-redux-firebase'
import ChannelsDiscoveryCard from '../components/ChannelsDiscoveryCard';

class Home extends Component {

  render() {
    return (
      <div>
        <div className="padded-row-big">
          <h1>Expo is a text messaging photo service.</h1>
          <p className="light">Expo sends you one photo from each of the channels you follow every day. <span role="img" aria-labelledby="Camera-Into-Phone-Hooray">📸📲⭐️</span> Setup a channel or follow someone today.</p>
          <p className="light">Want to follow a channel? Just text <a href="sms:+1-415-825-3976"><code className="code-light">subscribe channel-name</code></a> to <a href="sms:+1-415-825-3976"><code className="code-light">+1-415-825-3976</code></a>. Normal SMS and MMS rates apply.</p>
        </div>
        <ChannelsDiscoveryCard />
      </div>
    );
  }
}

export default compose(
  withFirebase,
  connect(({ firebase: { auth } }) => ({ auth })),
  connect(({ discoverChannels }) => ({ discoverChannels })),
)(Home)
