import { API } from 'aws-amplify';
import firebase from 'firebase/app'
import 'firebase/auth'
import appStage from './app-stage';


export async function headersWithAuth(headers = {}) {
  if (!firebase.auth().currentUser) {
    return Object.assign({}, headers, {});
  }
  let firebaseJWT = await firebase.auth().currentUser.getIdToken();
  return Object.assign({}, headers, {
    'X-Expo-Authorization': firebaseJWT
  });
}

export async function fetchOneItem({ itemId, channelName }) {
  let stage = appStage;
  let apiName = `expo-api-${stage}`;
  let path = `/channels/images/${channelName}/${itemId}`;
  try {
    let ini = { headers: await headersWithAuth() };
    let result = await API.get(apiName, path, ini);
    console.dir(result);
    if (result.result.error) {
      return { error: result.result.error };
    }
    return { item: result.result.item, channel: result.result.channel };
  } catch (error) {
    console.dir({ error });
    return { error };
  }
}

export async function deleteOneItem({ itemId, channelName }) {
  let stage = appStage;
  let apiName = `expo-api-${stage}`;
  let path = `/channels/images/${channelName}/${itemId}`;
  try {
    let ini = { headers: await headersWithAuth() };
    let result = await API.del(apiName, path, ini);
    console.dir(result);
    if (result.result.error) {
      return { error: result.result.error };
    }
    return { result: 'deleted' };
  } catch (error) {
    console.dir({ error });
    return { error };
  }
}

export async function unsubscribe({ userId, channelName }) {
  let stage = appStage;
  let apiName = `expo-api-${stage}`;
  let path = `/channels/subscriptions/${userId}/${channelName}`;
  try {
    let ini = { headers: await headersWithAuth() };
    let result = await API.del(apiName, path, ini);
    console.dir(result);
    if (result.result.error) {
      return { error: result.result.error };
    }
    return { result: 'unsubscribed' };
  } catch (error) {
    console.dir({ error });
    return { error };
  }
}


export async function fetchSubscriptions({ userId }) {
  let stage = appStage;
  let apiName = `expo-api-${stage}`;
  let path = `/channels/subscriptions/${userId}`;
  try {
    let ini = { headers: await headersWithAuth() };
    let result = await API.get(apiName, path, ini);
    console.dir(result);
    if (result.result.error) {
      return { error: result.result.error };
    }
    return { subs: result.result.channels };
  } catch (error) {
    console.dir({ error });
    return { error };
  }
}
