import React from "react";
import { Button, Modal } from 'react-bootstrap';


class DeleteModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleClose() {
    this.props.onCancel();
  }

  handleDelete() {
    this.props.onDelete();
  }

  handleShow() {
    this.setState({ show: true });
  }

  render() {
    return (
      <>
        <Modal show={this.props.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.props.body}</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button variant="secondary" onClick={this.handleDelete}>
              Confirm Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default DeleteModal;
