export const FirebaseConfig = {
  apiKey: "AIzaSyCFKfVTKjYoVoFPqjEoXsfib-fxkmL2gt8",
  authDomain: "expo-cb687.firebaseapp.com",
  databaseURL: "https://expo-cb687.firebaseio.com",
  projectId: "expo-cb687",
  storageBucket: "expo-cb687.appspot.com",
  messagingSenderId: "881308559727",
  appId: "1:881308559727:web:cdd44e5538dd5d38"
};

export default { FirebaseConfig };
