import awsmobile from './aws-exports';

const getStage = (a) => {
  console.log('get stage');
  if (a.aws_content_delivery_bucket.endsWith('-dev-prod')) {
    console.log('prod');
    return 'prod';
  } else if (a.aws_content_delivery_bucket.endsWith('-dev-dev')) {
    console.log('running in develop');
    return 'develop';
  }
  throw Error(`Unknown stage`);
}

const stage = getStage(awsmobile);

export default stage;
