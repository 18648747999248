import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withFirebase } from 'react-redux-firebase'
import { Link } from 'react-router-dom'
import { Navbar, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import appStage from '../app-stage';

class NavBar extends Component {
  render() {
    console.dir(this.props);
    let stage = appStage === 'prod' ? null : (<b><span role="img" aria-labelledby="ra11nb00ws">🌈{appStage}🌈</span></b>);
    let login = this.renderLogin();
    return (
      <Navbar bg="light" expand="lg">
        <Navbar.Brand>
          <Link to="/">Expo {stage}</Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            <LinkContainer to="/creator">
              <Nav.Link>Channel</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/subscriptions">
              <Nav.Link>Subscriptions</Nav.Link>
            </LinkContainer>
            {login}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }

  renderLogin() {
    if (this.props.auth && this.props.auth.phoneNumber && this.props.auth.phoneNumber.length > 0) {
      return (
        <Navbar.Text>
          {this.props.auth.phoneNumber}
        </Navbar.Text>
      );
    }
    return null;
  }
}

Navbar.propTypes = {
  auth: PropTypes.object,
};

const mapDispatchToProps = (_) => (
  {
  });

export default compose(
  withFirebase,
  connect(({ firebase: { auth } }) => ({ auth })),
  connect(null, mapDispatchToProps)
)(NavBar);
