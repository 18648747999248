import bigInt from 'big-integer';
import moment from 'moment';


export const isPublished = (item, channel) => {
  if (!channel.lastPublishedSeq) {
    return false;
  }
  if (channel.published) {
    return true;
  }
  return bigInt(item.seq) <= bigInt(channel.lastPublishedSeq);
}

export const humanPublishString = (i, channel) => {
  let pubStr;
  if (isPublished(i, channel)) {
    if (i.published) {
      pubStr = moment(new Date(i.published)).fromNow();
    } else {
      pubStr = 'Published';
    }
  } else {
    pubStr = null;
  }
  return pubStr;
}

export const decorateChannelItemsWithPublishDetails = (items, channel) => {
  if (!items || !channel) {
    return [];
  }

  var nextToPublish = null;
  let decorated = items.map((i) => {
    let pub = isPublished(i, channel);
    if (!pub && !nextToPublish) {
      nextToPublish = i;
    }
    var pubStr = humanPublishString(i, channel);
    if (!pubStr) {
      pubStr = (nextToPublish === i ? 'Next' : 'Future');
    }
    return Object.assign({}, i, {
      publish: pubStr,
      nextToPublish: nextToPublish === i
    });
  });
  console.dir(decorated);
  return decorated;
}
