import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withFirebase } from 'react-redux-firebase'
import { Button, Card, Accordion } from 'react-bootstrap';
import { deleteChannel } from "../actions";


class ChannelDeleteCard extends Component {
  constructor(props) {
    super(props)
    this.onDeleteChannel = this.props.onDeleteChannel.bind(this);
  }

  componentDidMount() {
  }

  render() {
    console.dir(this.props);
    var { creator, channel, onDeleteChannel } = this.props;

    return (
      <Accordion>
        <Card style={{ width: '18rem' }}>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0">
              Delete <code>@{channel.name}</code>
          </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <Card.Title>Delete Channel</Card.Title>
              <Card.Text>
                If you delete <code>{channel.name}</code>, you won't be able to get it back.
              </Card.Text>
              <Button onClick={() => onDeleteChannel(creator, channel.name)}>
                Delete
                </Button>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  }
}

ChannelDeleteCard.propTypes = {
  onDeleteChannel: PropTypes.func.isRequired,
  firebase: PropTypes.object.isRequired,
  creator: PropTypes.string.isRequired,
  channel: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => (
  {
    onDeleteChannel: (creatorId, channelName) => dispatch(deleteChannel({ creatorId, channelName }))
  });

export default compose(
  withFirebase,
  connect(({ firebase: { auth } }) => ({ auth })),
  connect(({ creatorId, channels }) => ({ creator: creatorId, channel: channels.items[0] })),
  connect(null, mapDispatchToProps)
)(ChannelDeleteCard);
